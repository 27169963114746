var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.permittedRoles &&
      _vm.computedRole &&
      _vm.permitted('UserRole.Update') &&
      _vm.isSuperAndIsLower &&
      !_vm.disabled
    )?_c('v-select',{attrs:{"item-text":"name","item-value":"userRoleId","items":_vm.permittedRoles,"disabled":!_vm.permitted('UserRole.Update'),"label":_vm.$t('user.role')},on:{"change":(v) => _vm.updateUserRole(v)},model:{value:(_vm.computedRole),callback:function ($$v) {_vm.computedRole=$$v},expression:"computedRole"}}):_c('span',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.getNameForRole(_vm.computedRole)))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }